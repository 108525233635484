import css from "./css/footer.module.css"
import logo from "../images/icon/logo.png"
import wtsapp_logo from "../images/icon/whatsapp_icon.png"
import fb_logo from "../images/icon/facebook.png"
import { HashLink } from "react-router-hash-link"
import { useMediaQuery } from "react-responsive"
import { useNavigate } from "react-router-dom"
import { useData } from "../DataContext"
import { wrapText } from "../ults/wrapText"
import { searchDescByLabel } from "../ults/searchDescByLabel"

const Footer = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" })
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" })
  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" })
  const navigate = useNavigate()

  const data = useData()?.content?.mt

  const address = wrapText(searchDescByLabel(data, "address"))

  const license = searchDescByLabel(data, "licenseNo")

  const phone = searchDescByLabel(data, "phone")
  const whatsapp = searchDescByLabel(data, "whatsapp")
  const fax = searchDescByLabel(data, "fax")

  const formatPhone = (phone) => {
    return phone?.replace(/(\d{4})(\d{4})/, "$1-$2")
  }

  return (
    <>
      {isDesktop || isTablet ? (
        <div className={css.container}>
          <div className={css.section1_container}>
            <div className={css.column1}>
              <a>
                <img
                  src={logo}
                  alt=""
                  className={css.logo}
                  onClick={() => {
                    navigate("/")
                  }}
                />
              </a>
              <br />
              <p className={css.licence}>放債人牌照號碼：{license}</p>
              <br />
              <table>
                <tbody>
                  <tr>
                    <td style={{ whiteSpace: "nowrap", verticalAlign: "top" }}>
                      公司地址：{address}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap", verticalAlign: "top" }}>
                      服務熱線：
                      <a href={`tel:+852${phone}`}>{formatPhone(phone)}</a>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap", verticalAlign: "top" }}>
                      FAX：<a href={`tel:+852${fax}`}>{formatPhone(fax)}</a>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "nowrap", verticalAlign: "top" }}>
                      投訴熱線：
                      <a href={`tel:+852${phone}`}>{formatPhone(phone)}</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className={css.column2}>
              <h3 className="mb-4 color4">貸款產品</h3>
              <HashLink smooth to="/privateloan">
                <p>私人貸款</p>
              </HashLink>
              <HashLink smooth to="/mortgage">
                <p>業主應急錢</p>
              </HashLink>
              <HashLink smooth to="/creditcard">
                <p>大額清卡數</p>
              </HashLink>
            </div>

            <div className={css.column3}>
              <div className={css.column3_1}>
                <h3 className="mb-4 color4">貸款申請</h3>
                <HashLink smooth to="/aboutus">
                  <p>申請流程</p>
                </HashLink>
                <HashLink smooth to="/qa">
                  <p>申請表格</p>
                </HashLink>
              </div>
              <div className="d-flex mb-3 mt-3">
                <a href={`https://api.whatsapp.com/send/?phone=852${whatsapp}`}>
                  <img src={wtsapp_logo} alt="" className={css.social_logo} />
                </a>
                <a
                  href={`https://www.facebook.com/profile.php?id=100076074545255`}
                >
                  <img src={fb_logo} alt="" className={css.social_logo} />
                </a>
              </div>
            </div>
          </div>

          <div
            className="d-flex flex-column mt-3"
            style={{ paddingLeft: "9%" }}
          >
            <i>
              <h1 className="color4">忠告：</h1>
            </i>
            <i>
              {" "}
              <h1 className="color4">借錢梗要還，咪俾錢中介</h1>
            </i>
            <p>© Fastearn Finance Limited版權所有</p>
          </div>
        </div>
      ) : (
        <div className={css.container_mobile}>
          <div className={css.section1_container_mobile}>
            <a>
              <img
                src={logo}
                alt=""
                className={css.logo}
                onClick={() => {
                  navigate("/")
                }}
              />
            </a>
            <p>放債人牌照號碼：{license}</p>
            <div className={css.page_link_mobile}>
              <h3 className="mb-4">貸款產品</h3>
              <HashLink smooth to="/privateloan">
                <p>私人貸款</p>
              </HashLink>
              <HashLink smooth to="/mortgage">
                <p>業主應急錢</p>
              </HashLink>
              <HashLink smooth to="/creditcard">
                <p>大額清卡數</p>
              </HashLink>
              <HashLink smooth to="/aboutus">
                <p>關於我們</p>
              </HashLink>
              <HashLink smooth to="/qa">
                <p>常見問題</p>
              </HashLink>
            </div>
            <p className="p-1">公司地址： {address}</p>
            <p>
              服務熱線：
              <a href={`tel:+852${phone}`} style={{ color: "white" }}>
                {formatPhone(phone)}
              </a>
            </p>
            <p>
              FAX：
              <a href={`tel:+852${fax}`} style={{ color: "white" }}>
                {formatPhone(fax)}
              </a>
            </p>
            <div className={css.social_logo_wrapper}>
              <a href={`https://api.whatsapp.com/send/?phone=852${phone}`}>
                <img
                  src={wtsapp_logo}
                  alt="whatsapplogo"
                  className={`${css.social_logo_mobile} me-2`}
                />
              </a>
              <a
                href={`https://www.facebook.com/profile.php?id=100076074545255`}
              >
                <img
                  src={fb_logo}
                  alt="facebooklogo"
                  className={css.social_logo_mobile}
                />
              </a>
            </div>
            <p>© Fastearn Finance Limited版權所有</p>
            <hr className={css.divider} />
            <div className="d-flex flex-column align-items-center">
              <h1 className="mt-3 color4">忠告：借錢梗要還，咪俾錢中介</h1>

              <p>
                投訴熱線：<a href={`tel:+852${phone}`}>{formatPhone(phone)}</a>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Footer
