import css from "./css/thankyou.module.css"
import bg from "../images/applyForm/bg.png"

import { useEffect } from "react"

const Thankyou = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])

  return (
    <div className={`${css.container} marginFixTop`}>
      <div className={css.bg_container}>
        <div className={css.title}>
          <h1>助你踏出理想第一步</h1>
        </div>
      </div>
      <div className={css.form_container}>
        <div className={css.content}>
          <h2>恭喜閣下已成功遞交申請，我們會盡快通知您！</h2>
          {/* <h2>
            或 WhatsApp：
            <a href="https://wa.me/852xxxx" target="_blank">
              xxxx xxxx
            </a>
            &nbsp; 同我地客戶經理即時對話啦！
          </h2> */}
        </div>
      </div>
    </div>
  )
}

export default Thankyou
